<template>
  <div>
    <b-form-checkbox
      :checked="currentEntry.available || currentEditedEntry.available"
      @input="update"
      switch
    ></b-form-checkbox>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  methods: {
    update(value) {
      this.$store.dispatch(this.storeEditAction, {
        available: value,
      });
    },
  },
};
</script>
